.wrapper{
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    gap: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

.Fruits{
    justify-content: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.FruitsTitle{
    height: 90%;
    text-align: center;
    width: 100%;
}