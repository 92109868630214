.imageText{
    position: relative;
    border-radius: 25%;
    border-color: greenyellow;
    text-decoration: wavy;
    padding-top: 4rem;
}


.topAbout{
    background-size: cover;
    background-image: url('../images/orchard_sunset.jpg');
    height: 480px;
    padding-top: 80px;
    text-align: center;
    display: block;
}