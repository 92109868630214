.head{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid blue; 
}


.homemid{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border: 3px solid green; 
}

.bigdiv{
    border: 2px solid red;
    padding-top: 1%;
    height: 350px;
}

.image{
    padding-top: 2rem;
    padding-left: 2rem;
}