.FruitCard{
    overflow: hidden;
    box-shadow: 0 2px 20px;
    color: lightslategray;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.FruitImg{
    height: auto;
    width: 100%;
    object-fit: cover;
}

.FruitCardTitle{
    padding: 1rem;
}

.FruitCardDescript{
    padding: 0 1rem;
}
*,*::before,*::after{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}